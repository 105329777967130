<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="CAS No."
            name="casNo"
            v-model="searchParam.casNo"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="통계조사표"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['col15']">
          <q-chip
            v-for="(item, index) in chipDatas(props.row['col15'])"
            :key="index"
            outline square
            color="light-green"
            text-color="white"
            style="margin-bottom:4px !important;">
            {{item}}
          </q-chip>
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'statistics-hardcoding',
  components: {
  },
  props: {
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      searchParam: {
        casNo: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'id' },
          { index: 1, colName: 'id' },
          { index: 2, colName: 'id' },
          { index: 3, colName: 'id' },
          { index: 4, colName: 'id' },
          { index: 5, colName: 'id' },
          { index: 6, colName: 'id' },
          { index: 7, colName: 'id' },
          { index: 8, colName: 'id' },
          { index: 9, colName: 'id' },
          { index: 10, colName: 'id' },
          { index: 11, colName: 'id' },
          { index: 12, colName: 'id' },
          { index: 13, colName: 'id' },
          { index: 14, colName: 'id' },
          { index: 15, colName: 'id' },
        ],
        columns: [
          {
            name: 'col0',
            field: 'col0',
            label: '제품구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '화학자재',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '용도',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '물질구성',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '성상',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'inYear',
            field: 'inYear',
            label: '연 입고량(톤/년)',
            align: 'center',
            child: [
              {
                name: 'col5',
                field: 'col5',
                label: '제조',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col6',
                field: 'col6',
                label: '수입',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col7',
                field: 'col7',
                label: '구매',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col8',
                field: 'col8',
                label: '이월',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
          {
            name: 'outYear',
            field: 'outYear',
            label: '연 출고량(톤/년)',
            align: 'center',
            child: [
              {
                name: 'col9',
                field: 'col9',
                label: '사용',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col10',
                field: 'col10',
                label: '판매',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col11',
                field: 'col11',
                label: '수출',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col12',
                field: 'col12',
                label: '재고',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col13',
                field: 'col13',
                label: '손실/폐기',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
          {
            name: 'col14',
            field: 'col14',
            label: '나노물질 포함 여부',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'facility',
            field: 'facility',
            label: '취급시설 현황',
            align: 'center',
            child: [
              {
                name: 'col15',
                field: 'col15',
                label: '시설 / 최대 보관 저장량(톤)',
                align: 'left',
                style: 'width:80px',
                sortable: false,
                type: 'custom',
              },
            ]
          },
          {
            name: 'chem',
            field: 'chem',
            label: '구성성분',
            align: 'center',
            child: [
              {
                name: 'col16',
                field: 'col16',
                label: '물질명',
                align: 'left',
                style: 'width:200px',
                sortable: false,
              },
              {
                name: 'col17',
                field: 'col17',
                label: 'CAS No.',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'col18',
                field: 'col18',
                label: '함유량(%)',
                align: 'right',
                style: 'width:80px',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          id: 1,
          col0: '제조(또는 수입)', 
          col1: '소포제 : EVERCRIN AF-100(HANSU)', 
          col2: '폐수처리용', 
          col3: '복합물질', 
          col4: '액체', 
          col5: '3.24', 
          col6: '1.68', 
          col7: '2.4', 
          col8: '0.24', 
          col9: '1.8', 
          col10: '1.2', 
          col11: '1.4', 
          col12: '3.89', 
          col13: '0.21', 
          col14: '제조', 
          col15: 'A시설(보관시설[창고])/10TON,B시설(저장시설[탱크])/5TON', 
          col16: 'Siloxanes and Silicones, di-Me', 
          col17: '63148-62-9', 
          col18: '20', 
        },
        {
          id: 1,
          col0: '제조(또는 수입)', 
          col1: '소포제 : EVERCRIN AF-100(HANSU)', 
          col2: '폐수처리용', 
          col3: '복합물질', 
          col4: '액체', 
          col5: '3.24', 
          col6: '1.68', 
          col7: '2.4', 
          col8: '0.24', 
          col9: '1.8', 
          col10: '1.2', 
          col11: '1.4', 
          col12: '3.89', 
          col13: '0.21', 
          col14: '제조', 
          col15: 'A시설(보관시설[창고])/10TON,B시설(저장시설[탱크])/5TON', 
          col16: 'Water', 
          col17: '7732-18-5', 
          col18: '80', 
        },
        {
          id: 2,
          col0: '제품 제조용 원료, 부원료, 첨가제, 촉매 등', 
          col1: '중화제 : UNI FILM 2020', 
          col2: '폐수처리용', 
          col3: '복합물질', 
          col4: '액체', 
          col5: '4.24', 
          col6: '2.68', 
          col7: '2.4', 
          col8: '1.24', 
          col9: '2.8', 
          col10: '2.2', 
          col11: '2.4', 
          col12: '4.89', 
          col13: '0.21', 
          col14: '수입', 
          col15: 'A시설(보관시설[창고])/7TON,C시설(저장시설[탱크])/3TON', 
          col16: '(1-Hydroxyethylidene)bisphosphonic acid; Etidronic acid', 
          col17: '2809-21-4', 
          col18: '40', 
        },
        {
          id: 2,
          col0: '제품 제조용 원료, 부원료, 첨가제, 촉매 등', 
          col1: '중화제 : UNI FILM 2020', 
          col2: '폐수처리용', 
          col3: '복합물질', 
          col4: '액체', 
          col5: '4.24', 
          col6: '2.68', 
          col7: '2.4', 
          col8: '1.24', 
          col9: '2.8', 
          col10: '2.2', 
          col11: '2.4', 
          col12: '4.89', 
          col13: '0.21', 
          col14: '수입', 
          col15: 'A시설(보관시설[창고])/7TON,C시설(저장시설[탱크])/3TON', 
          col16: 'Water', 
          col17: '7732-18-5', 
          col18: '60', 
        },
      ]
    },
    chipDatas(col15) {
      return this.$_.split(col15, ',');
    },
  }
};
</script>