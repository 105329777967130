var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: { label: "CAS No.", name: "casNo" },
                  model: {
                    value: _vm.searchParam.casNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "casNo", $$v)
                    },
                    expression: "searchParam.casNo",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "통계조사표",
            tableId: "table",
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["col15"]
                    ? _vm._l(
                        _vm.chipDatas(props.row["col15"]),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                outline: "",
                                square: "",
                                color: "light-green",
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }
                      )
                    : void 0,
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }